export const VCTK_SAMPLES = [
  {
    Sentence:
      'Demok<span class="relative"><span class="highlight">r</span></span>atie entsteht wom<span class="relative"><span class="highlight">ö</span></span>gli<span class="relative"><span class="highlight">ch</span></span> erst in diesem Konflikt.',
    "Unseen phonemes": "[R], [ø], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p240_027.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p240_027.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p240_027.wav"
  },
  {
    Sentence:
      'Allerdings wurde dieser Beschluss ni<span class="relative"><span class="highlight">ch</span></span>t sofort umgesetzt.',
    "Unseen phonemes": "[ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p240_042.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p240_042.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p240_042.wav"
  },
  {
    Sentence:
      'Es wurde <span class="relative"><span class="highlight">ü</span></span>ber zw<span class="relative"><span class="highlight">ö</span></span>lf Millionen Mal verkauft.',
    "Unseen phonemes": "[y], [œ]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p254_004.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p254_004.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p254_004.wav"
  },
  {
    Sentence:
      'Die Bl<span class="relative"><span class="highlight">ü</span></span>tezeit <span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>t von Juni bis September.',
    "Unseen phonemes": "[y], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p254_043.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p254_043.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p254_043.wav"
  },
  {
    Sentence:
      'Schwerpunkt seiner Täti<span class="relative"><span class="highlight">g</span></span>keit blieb jedoch die Schauspiele<span class="relative"><span class="highlight">r</span></span>ei.',
    "Unseen phonemes": "[ç], [R]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p254_023.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p254_023.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p254_023.wav"
  },
  {
    Sentence:
      'Meistens ist eine Behandlung der S<span class="relative"><span class="highlight">y</span></span>mptome aus<span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>end.',
    "Unseen phonemes": "[y], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p276_041.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p276_041.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p276_041.wav"
  },
  {
    Sentence:
      'Die Maschine wurde dur<span class="relative"><span class="highlight">ch</span></span> das Feuer zerst<span class="relative"><span class="highlight">ö</span></span>rt.',
    "Unseen phonemes": "[ç], [ø]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/PHON/p276_034.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/MAPPED/p276_034.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX_VCTK/RANDOM/p276_034.wav"
  }
];

export const ADRIANEX_samples = [
  {
    Sentence:
      'Demok<span class="relative"><span class="highlight">r</span></span>atie entsteht wom<span class="relative"><span class="highlight">ö</span></span>gli<span class="relative"><span class="highlight">ch</span></span> erst in diesem Konflikt.',
    "Unseen phonemes": "[R], [ø], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a02_027.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a02_027.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a02_027.wav"
  },
  {
    Sentence:
      'Allerdings wurde dieser Beschluss ni<span class="relative"><span class="highlight">ch</span></span>t sofort umgesetzt.',
    "Unseen phonemes": "[ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a02_042.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a02_042.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a02_042.wav"
  },
  {
    Sentence:
      'Es wurde <span class="relative"><span class="highlight">ü</span></span>ber zw<span class="relative"><span class="highlight">ö</span></span>lf Millionen Mal verkauft.',
    "Unseen phonemes": "[y], [œ]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a01_004.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a01_004.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a01_004.wav"
  },
  {
    Sentence:
      'Die Bl<span class="relative"><span class="highlight">ü</span></span>tezeit <span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>t von Juni bis September.',
    "Unseen phonemes": "[y], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a07_043.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a07_043.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a07_043.wav"
  },
  {
    Sentence:
      'Schwerpunkt seiner Täti<span class="relative"><span class="highlight">g</span></span>keit blieb jedoch die Schauspiele<span class="relative"><span class="highlight">r</span></span>ei.',
    "Unseen phonemes": "[ç], [R]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a05_023.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a05_023.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a05_023.wav"
  },
  {
    Sentence:
      'Meistens ist eine Behandlung der S<span class="relative"><span class="highlight">y</span></span>mptome aus<span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>end.',
    "Unseen phonemes": "[y], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a09_041.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a09_041.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a09_041.wav"
  },
  {
    Sentence:
      'Die Maschine wurde dur<span class="relative"><span class="highlight">ch</span></span> das Feuer zerst<span class="relative"><span class="highlight">ö</span></span>rt.',
    "Unseen phonemes": "[ç], [ø]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/PHON/a05_034.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/MAPPED/a05_034.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/MIX-Adrianex/RANDOM/a05_034.wav"
  }
];

export const ZERO_SHOT = [
  {
    Sentence:
      'Demok<span class="relative"><span class="highlight">r</span></span>atie entsteht wom<span class="relative"><span class="highlight">ö</span></span>gli<span class="relative"><span class="highlight">ch</span></span> erst in diesem K<span class="relative"><span class="highlight">o</span></span>nflikt.',
    "Unseen phonemes": "[R], [ø], [ç], [o]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p240_027.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p240_027.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p240_027.wav"
  },
  {
    Sentence:
      'Allerdings wurde dieser Beschluss ni<span class="relative"><span class="highlight">ch</span></span>t s<span class="relative"><span class="highlight">o</span></span>fort umgesetzt.',
    "Unseen phonemes": "[ç], [o]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p240_042.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p240_042.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p240_042.wav"
  },
  {
    Sentence:
      'Es wurde <span class="relative"><span class="highlight">ü</span></span>ber zw<span class="relative"><span class="highlight">ö</span></span>lf Milli<span class="relative"><span class="highlight">o</span></span>nen Mal verkauft.',
    "Unseen phonemes": "[y], [œ], [o]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p254_004.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p254_004.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p254_004.wav"
  },
  {
    Sentence:
      'Die Bl<span class="relative"><span class="highlight">ü</span></span>tezeit <span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>t von Juni bis September.',
    "Unseen phonemes": "[y], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p254_043.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p254_043.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p254_043.wav"
  },
  {
    Sentence:
      'Schwerpunkt seiner Täti<span class="relative"><span class="highlight">g</span></span>keit blieb jedo<span class="relative"><span class="highlight">ch</span></span> die Schauspiele<span class="relative"><span class="highlight">r</span></span>ei.',
    "Unseen phonemes": "[ç], [x], [R]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p254_023.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p254_023.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p254_023.wav"
  },
  {
    Sentence:
      'Meistens ist eine Behandlung der S<span class="relative"><span class="highlight">y</span></span>mpt<span class="relative"><span class="highlight">o</span></span>me aus<span class="relative"><span class="highlight">r</span></span>ei<span class="relative"><span class="highlight">ch</span></span>end.',
    "Unseen phonemes": "[y], [o], [R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p276_041.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p276_041.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p276_041.wav"
  },
  {
    Sentence:
      'Die Maschine wurde dur<span class="relative"><span class="highlight">ch</span></span> das Feuer zerst<span class="relative"><span class="highlight">ö</span></span>rt.',
    "Unseen phonemes": "[ø], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/PHON/p276_034.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/MAPPED/p276_034.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/samples-nv/VCTK/RANDOM/p276_034.wav"
  }
];

export const VCTK_CODE_SWITCH = [
  {
    Sentence:
      'Alb<span class="relative"><span class="highlight">r</span></span>e<span class="relative"><span class="highlight">ch</span></span>t D<span class="relative"><span class="highlight">ür</span></span>er, sometimes spelled in English as Durer, without umlaut, was a German painter.',
    "Unseen phonemes": "[R], [ç], [y]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/PHON/p254_durer.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/MAPPED/p254_durer.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/RANDOM/p254_durer.wav"
  },
  {
    Sentence:
      'The German name of the city Munich is M<span class="relative"><span class="highlight">ü</span></span>n<span class="relative"><span class="highlight">ch</span></span>en',
    "Unseen phonemes": "[y], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/PHON/p276_munich.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/MAPPED/p276_munich.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/RANDOM/p276_munich.wav"
  },
  {
    Sentence:
      'Eugen Berthold F<span class="relative"><span class="highlight">r</span></span>ied<span class="relative"><span class="highlight">r</span></span>i<span class="relative"><span class="highlight">ch</span></span> B<span class="relative"><span class="highlight">r</span></span>e<span class="relative"><span class="highlight">ch</span></span>t was a German theatre practitioner, playwright, and poet.',
    "Unseen phonemes": "[R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/PHON/p254_brecht.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/MAPPED/p254_brecht.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/RANDOM/p254_brecht.wav"
  },
  {
    Sentence:
      'G<span class="relative"><span class="highlight">ö</span></span>del, Escher, Ba<span class="relative"><span class="highlight">ch</span></span>: an Eternal Golden Braid, also known as GEB, is a book by Douglas Hofstadter.',
    "Unseen phonemes": "[œ], [x]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/PHON/p276_ged.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/MAPPED/p276_ged.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/VCTK/RANDOM/p276_ged.wav"
  }
];

export const MIX_CODE_SWITCH = [
  {
    Sentence:
      'Alb<span class="relative"><span class="highlight">r</span></span>e<span class="relative"><span class="highlight">ch</span></span>t D<span class="relative"><span class="highlight">ür</span></span>er, sometimes spelled in English as Durer, without umlaut, was a German painter.',
    "Unseen phonemes": "[R], [ç], [y]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/PHON/p254_durer.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/MAPPED/p254_durer.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/RANDOM/p254_durer.wav"
  },
  {
    Sentence:
      'The German name of the city Munich is M<span class="relative"><span class="highlight">ü</span></span>n<span class="relative"><span class="highlight">ch</span></span>en',
    "Unseen phonemes": "[y], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/PHON/p276_munich.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/MAPPED/p276_munich.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/RANDOM/p276_munich.wav"
  },
  {
    Sentence:
      'Eugen Berthold F<span class="relative"><span class="highlight">r</span></span>ied<span class="relative"><span class="highlight">r</span></span>i<span class="relative"><span class="highlight">ch</span></span> B<span class="relative"><span class="highlight">r</span></span>e<span class="relative"><span class="highlight">ch</span></span>t was a German theatre practitioner, playwright, and poet.',
    "Unseen phonemes": "[R], [ç]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/PHON/p254_brecht.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/MAPPED/p254_brecht.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/RANDOM/p254_brecht.wav"
  },
  {
    Sentence:
      'G<span class="relative"><span class="highlight">ö</span></span>del, Escher, Bach: an Eternal Golden Braid, also known as GEB, is a book by Douglas Hofstadter.',
    "Unseen phonemes": "[œ]",
    AUTO:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/PHON/p276_ged.wav",
    MAPPED:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/MAPPED/p276_ged.wav",
    RANDOM:
      "https://papercup-storage.s3-eu-west-1.amazonaws.com/publications/phonological-features-2020/codeswitched-samples-nv/MIX_VCTK/RANDOM/p276_ged.wav"
  }
];
